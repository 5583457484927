import { storeImports } from './storeImports'

// Track initialization status of stores
const initializationStatus = {
  system: false,
  user: false
}

// Store instances cache with direct references (not wrapped in Promises)
let systemStoreInstance = null
let userStoreInstance = null

/**
 * Initialize critical stores and preload data
 * @returns {Promise<{systemStore, userStore}>}
 */
export async function initializeStores() {
  // Initialize user store first
  const user = await getUserStore();
  // Then initialize system store, which depends on user auth state
  const system = await getSystemStore();

  return {
    systemStore: system,
    userStore: user
  }
}

/**
 * Get system store instance, initializing it if needed
 * @returns {Promise<Object>} System store instance
 */
export async function getSystemStore() {
  if (systemStoreInstance) {
    return systemStoreInstance
  }

  systemStoreInstance = await storeImports.system()
  
  // Preload system data if authenticated and not already loaded
  if (!initializationStatus.system && userStoreInstance?.isAuthenticated) {
    try {
      await preloadSystemData(systemStoreInstance)
      initializationStatus.system = true
    } catch {
      // console.error('Failed to preload system data:', error)
    }
  }

  return systemStoreInstance
}

/**
 * Get user store instance, initializing it if needed
 * @returns {Promise<Object>} User store instance
 */
export async function getUserStore() {
  if (userStoreInstance) {
    return userStoreInstance
  }

  userStoreInstance = await storeImports.user()
  initializationStatus.user = true
  
  return userStoreInstance
}

/**
 * Preload system data if needed
 * @param {Object} systemStore - System store instance
 */
async function preloadSystemData(systemStore) {
  if (systemStore.isSystemDataEmpty()) {
    await systemStore.fetchSystemData()
  }
}

/**
 * Reset store instances (useful for logout or testing)
 */
export function resetStoreInstances() {
  systemStoreInstance = null
  userStoreInstance = null
  initializationStatus.system = false
  initializationStatus.user = false
}